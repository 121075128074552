footer.container
  padding 35px 20px
  .address
    margin-bottom 24px
    span
      font-size 12px
  .copy
    font-size 12px
    line-height 40px
    font-family: RobotoBold

  p
    font-size 12px
    font-weight normal
    font-family: Helvetica
    line-height 30px
    width: calcWidth(440, 718)
    margin-top 8px
.b-socoals
  .coc-item
    width: 30px
    height: 30px
    border-radius 50%
    display inline-block
    margin-right 20px
    box-shadow 0 0 15px rgba(0,0,0,.15)
    text-align center
    transition all .3s ease
    &:hover
      i
        color #fff
        box-shadow none
    &.fb
      &:hover
        background #3b5998
    &.tw
      &:hover
        background #55acee
    &.vk
      &:hover
        background #4d75a3
    i
      transition all .3s ease

    .fa-facebook
      color #4d75a3
    .fa-twitter
      color #55acee
    .fa-vk
      color #4d75a3
.develop
  margin-top 25px
  font-family: RobotoMedium
  .red
    padding-left 4px




.fixed
  position fixed
  top: 0
  width: 100%
  padding 25px 0
  z-index 2

.logo,.nav,.phone
  display inline-block
  vertical-align: middle
.logo
  margin-right 54px
.phone
  float right
  position: relative
  top: 7px
  a
    font-weight: bold
    letter-spacing: 2px
.nav
  cf()
  &__item
    font-family: Roboto
    font-weight: 500
    padding 0 (49/2)px
    display inline-block
    position: relative
    &:before
      opacity 0
      transition all .3s ease
      content ''
      position: absolute
      bottom: -5px
      width: 15px
      height: 2px
      background $white
      left: 0
      right: 0
      margin: auto
    &:hover
      &:before
        opacity 1
.shadow
  box-shadow: 0 1px 5px rgba(0,0,0,.5)
.map
  height 735px
.contacts
  border-top 4px solid $red
  position: absolute
  top 70px
  left 0
  right auto
  margin auto
  z-index: 1
  max-width 500px
  background $white
  padding 33px 65px
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.2)
  .title
    color $red
    margin-bottom 33px
    font-family: RobotoMedium
  .btn
    position: absolute
    right -60px
    bottom -25px

.contianer__contacts
  j_center(1060px)
  position: relative

.pos-abs
  position absolute
  top 0
  width: calc( 100% - 240px)
  left: 0
  right: 0
  z-index: 1
.address
  span
    line-height 40px
    display: block
.line-to-point
  position: absolute
  width: 1px
  right 0
  height: 580px
  background: #333
  bottom: 100px
  transform: rotate(118.5deg)
  transform-origin: 0 100%
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled
  opacity 1
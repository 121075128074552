.title_h1
  font-family: OpenSansExBold
  font-size: 42px
  font-weight: 800
  line-height 60px
  color $white
.f-light
  font-weight: 100
  font-family: OpenSansLight
.title
  &--big
    font-size 50px
    font-family OpenSansExBold
    color $btnGray
    line-height 60px
.title-red
  font-size 28px
  font-family: RobotoMedium
  line-height 50px
  color #ee5050
.title--right
  float right

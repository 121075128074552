.checkbox-hide
  display none
.checkbox
  position: relative
  padding-left 30px
  display: block
  cursor pointer
.checkbox:before
  content ''
  width: 21px
  height: 21px
  border-radius 5px
  background #4d6688
  position: absolute
  left 0
  top 50%
  margin-top -(@height/2)px
  transition all .3s ease

.checkbox-input:checked + .checkbox
  &:before
    content '✔';
    background cFuxia
    font-size rem(16px)
    text-align center
    color cWhite
.checkbox-toogle
  position: relative
  width: 40px
  height: 16px
  border-radius 60px
  background #4d6688
  display block
  float right
  top 4px
  cursor pointer
  transition all .5s ease
  &:before
    content ''
    position absolute
    display: block
    left 0
    width: 16px
    height: 16px
    border-radius 50%
    background cFuxia
    transition all .5s ease
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.41)
  &:after
    content ''
    display: block
    position: absolute
    left 0
    right: 0
    height 17px
    border-radius 60px
    transition all .5s ease
.checkbox-toogle-input:checked+.checkbox-toogle
  background #4cbdc2
  &:before
    left 25px












font-face( "fontAwesome", "../fonts/fontawesome-webfont", normal)
font-face( "Open Sans", "../fonts/OpenSans", normal)
font-face( "OpenSansLight", "../fonts/OpenSans-Light", normal)
font-face( "OpenSansExBold", "../fonts/OpenSans-Extrabold", normal)
font-face( "Helvetica", "../fonts/Helvetica", normal)
font-face( "Roboto", "../fonts/Roboto-Regular", normal)
font-face( "RobotoLight", "../fonts/Roboto-Light", normal)
font-face( "RobotoMedium", "../fonts/Roboto-Medium", normal)
font-face( "RobotoBold", "../fonts/Roboto-Bold", normal)

.wow
  visibility: hidden;
*
  -webkit-font-smoothing: antialiased

jeet = {
  gutter: (25/1090)*100,
  parent-first: false,
  layout-direction: LTR,
  max-width: 1280px
}

//braekpoints
tablet = 1280px
phone = 540px
gutter = (29/1170)*100


//class helpers
.bg-red
  background $red
.red,.red a
  color $red
.overflow
  overflow hidden
.preloader
  position: fixed
  top: 0
  width: 100%
  display: block
  height: 100%
  background: #ee5050
  z-index: 3
  img
    position: absolute
    left: 0
    right: 0
    bottom: 0
    top: 0
    margin auto

a
  color $white
  text-decoration: none

body
  font rem(14px) Helvetica, sans-serif
  line-height rem(28px)
  color $gray
  background #fff
#container_paralax
  overflow hidden
.container
  j_center(1040px, pad: 120px)
  cf()
.rel
  position relative

.table
  display table
.v-center
  display table-cell
  vertical-align middle


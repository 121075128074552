.service
  padding 107px 0 0 0
.services
  margin-top 75px

  cf()
  &__item
    width: 285px
    display inline-block
    margin-right: calcWidth(88, 1040)

    &:last-child
      margin-right 0
  &__title
    font-size: 18px
    font-family: RobotoMedium
    font-weight: 500
    height: 211px
    position: relative
    transition all .3s ease
    &:hover
      color $red
    .number
      font-size 180px
      color #f5f5f5
      font-family: RobotoBold
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      margin auto
      display: block
      line-height 211px
      z-index -1
      font-weight 900
      cursor pointer
.service-block
  padding 0 0px 177px 0px
  position: relative
  cf()
  &:last-child
    padding-bottom 177px
  &:first-child
    padding-top 146px
  .icon
    position: absolute
    top: 145px
    right: 65px
  &__left
    width:  (464/1040)*100%
    float left
  &__right
    width:  (576/1040)*100%
    float left
  //.image-block
  .white-block
    z-index 1
    top -17px
    position: relative
    background #fff
    padding 45px
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15)
.image-service
  max-width 100%
  padding-top 40px
.service-list
  list-style none
  list-style none
  padding-left 50px
  padding-bottom 55px
  &__item
    line-height 40px
$widthS = (577/1040)*100%
.not--float
  .service-block__left
    float right













.header
  padding 155px 0 108px
.header-description
  color: $white
  width: 500px
  margin 45px 0 45px 0
  display: block
  font-family: Helvetica


.line-1
  position: absolute
  top 90px
  width 595px
  left 263px
.line-2
  position: absolute
  top: -200px
  width: 2px
  left: 120px
.line-3
  top: 190px
  width: 235px
  left: 120px
  position: absolute
.line-4
  width: 2px
  position: absolute
  left: 125px
  top: -129px
.line-5
  width: 326px
  position: absolute
  left: -196px
  top: 120px
.line-6
  width: 232px;
  position: absolute;
  left: 260px;
  bottom: 16px;

.table-header
  j_center(1040px)
  width 100%
  height 100%
  display: table
  .header
    display table-cell
    vertical-align middle
.note
  position: absolute;
  top: 0
  bottom: 0
  right: 0
  left: auto
  margin: auto
.book
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: auto
  margin auto
  max-height 550px

//mouse
.mouse
  margin: auto
  position absolute
  top auto
  bottom: 45px
  left: 0
  right: 0
  width: 30px
  margin 20px auto 0 auto
.logo-mini
  display none

.mouse-icon
   width: 25px
   height: 45px
   border: 2px solid white
   border-radius: 15px
   cursor: pointer
   position: relative
   text-align: center
.mouse-wheel
    height: 6px
    margin: 2px auto 0
    display: block
    width: 3px
    background-color: white
    border-radius: 50%
    animation: 1.6s ease infinite wheel-up-down

@keyframes wheel-up-down
    0%
      margin-top: 2px
      opacity: 0

    30%
      opacity: 1

    100%
      margin-top: 20px
      opacity: 0
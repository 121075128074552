.cross
  position: absolute
  width: 20px
  height: hCross = 20px
  right 0
  top 50%
  margin-top tCross = -12px
  &__line
    transition all .5s ease
    width 100%
    height 2px
    background cFuxia
    position absolute
    top (hCross/2)
    transform rotate(45deg)
    &:nth-child(2)
      transform rotate(-45deg)
.nav__close
  &:hover
    .cross
      &__line
        transform rotate(0deg)
        &:nth-child(2)
          transform rotate(0deg)
.clients
  padding-top 120px
  margin-bottom 100px
  cf()

.lock-img
  position: absolute
  right -50px
  top -50px
  z-index: -1
.mac-img
  position: absolute
  left -120px
  top -380px

.b-clients
  width calcWidth(615, 1040)
  cf()
  padding-bottom 220px
  &__item
    col(1/2, cycle:2)
    height: 200px
    position: relative
    margin-bottom 40px
    &:hover
      img
        filter: none
    &:nth-of-type(2n+1)
      clear:none
    img
      position absolute
      max-width: 100%
      max-height: 100%
      left: 0
      bottom: 0
      top: 0
      right: 0
      margin auto
      filter: grayscale(100%)
      transition all .3s ease

._1c-img
  bottom 0
  right 0
  position: absolute
.swiper-container
  //width calcWidth(767, 1040)
  width: 100%
  float: right
  top 150px
  position: absolute
  right: auto
  left: 430px
  padding: 75px 0px 40px 75px
.swiper-button-next
  background $red
  width: 45px
  height: 45px
  position: absolute
  right: auto
  left: 95px
  margin-top: 0
  top: 253px
  transition all .3s ease
  &:hover
    background $lightRed
  &:before
    content ''
    position: absolute
    width: 2px
    height: 21px
    background $white
    display: block
    top 5px
    bottom: auto
    left: 0
    right: 0
    margin auto
    transform rotate(-45deg)
  &:after
    content ''
    position: absolute
    width: 2px
    height: 21px
    background $white
    display: block
    top auto
    bottom: 5px
    left: 0
    right: 0
    margin auto
    transform rotate(45deg)
.swiper-button-prev
  background $btnGray
  width: 45px
  height: 45px
  bottom: auto
  margin-top: 0
  top: 232px
  position: absolute
  right: auto
  left: 50px
  transition all .3s ease
  &:hover
    background $lightGray
  &:before
    content ''
    position: absolute
    width: 2px
    height: 21px
    background $white
    display: block
    top 5px
    bottom: auto
    left: 0
    right: 0
    margin auto
    transform rotate(-135deg)
  &:after
    content ''
    position: absolute
    width: 2px
    height: 21px
    background $white
    display: block
    top auto
    bottom: 5px
    left: 0
    right: 0
    margin auto
    transform rotate(135deg)
.section-reviews
  min-height 587px
.reviews
  &__img
    width: 150px
    height: 150px
    position: absolute
    left: -75px
    top: -75px
    overflow hidden
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2)
    img
      max-width: 100%
      position: absolute
      left: 0
      right: 0
      bottom: 0
      top: 0
      margin auto
  &__title
    margin-bottom 30px
  &__text
    max-width 508px
  p
    line-height 30px
  &__item
    padding 57px 120px 57px 140px
    background $white
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15)

.title_person
  font-family: RobotoMedium
  font-size 16px
  color $red
  border-right 1px solid #ddd
  padding-right 12px
  margin-right 12px
.person_position
  font-family: RobotoMedium
  font-size 16px
  color $btnGray



.overlay
  position fixed
  width: 100%
  height 100%
  top 0
  background rgba(0,0,0,.8)
  z-index 9
  display none
.overflow
  overflow hidden
.modal
  width: 580px - 62*2
  background #fff
  position: absolute
  left: 0
  right: 0
  top: 0
  bottom: 0
  margin auto
  padding 50px 62px
  height 300px - 52*2
  text-align center
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1)
  .btn
    margin-top 15px
    border none
    margin 15px auto 0px auto
  .group-input
    display inline-block
    position: relative
    margin-top 40px
    margin-right 65px
    &:last-of-type
      margin-right 0
    label
      position absolute
      top 12px
      left 10px
      display: inline-block
      font-size 14px
      color #3f404b
      transition all .3s ease
    input
      padding-left 25px
      width 190px - 25
      display inline-block
      border none
      border-bottom 2px solid #ddd
      height 50px
      margin-bottom 40px
      outline none
      display inline-block
      &:focus
        outline none
.close
  position: absolute
  top: 35px
  right: 35px
  width: 30px
  height: 30px
  cursor pointer
  &:after
    content ''
    width 1px
    height 20px
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin auto
    background $black
    transform rotate(45deg)
  &:before
    content ''
    width 1px
    height 20px
    position: absolute
    top: 0
    background $black
    bottom: 0
    left: 0
    right: 0
    margin auto
    transform rotate(-45deg)
.active-input
  label
    transform translateY(-30px)
.modal .group-input.faild
  label
    color $red
  input
    border-color $red
.btn
  width: 250px
  height: 55px
  display block
  line-height 55px
  color #fff
  cursor pointer
  font-size 14px
  font-family: Roboto
  font-weight 500
  text-align: $center
  transition all .3s ease
  &--left
    left -60px
    right auto
  &--right
    left auto
    right 0
  &--gray
    background: $btnGray
    &:hover
      background #5c5d70
  &--red
    background: $red
    &:hover
      background #fe7676
.btn__show-price
  width 55px
  height 55px
  line-height 55px
  font-family: RobotoMedium
  overflow hidden
  color $white
  transition all .3s ease
  position: absolute
  right 0
  bottom 0
  cursor pointer
  &:hover
    width: 187px
    .text
      opacity 1
    img
      background #5c5d70
  .text
    padding-left 17px
    opacity 0
    display inline-block
    transition all .5s ease
  img
    position: absolute
    right: 0px
    top: 0
    padding 15px
    background $btnGray
    bottom: 0
    margin auto
    transition background .3s ease
.btn__order-price
  left -60px
  bottom 0
  position: absolute
.btn--center
  margin 0 auto
  z-index 1
  position: relative
//invert show btn parent class .service-block
.btn--invert
  .btn__show-price
    text-align right
    left 0
    img
      background $red
    &:hover
      width: 188px
      img
        background #fe7676
    img
      left 0px
      right auto
    .text
      padding-right 14px
      padding-left 0
  .btn__order-price
    right -60px
    left auto
    z-index 1







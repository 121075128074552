mobile = {
  desktop: 1280px,
  tablet: 767px,
  pad: 1024px,
  phone: 540px

}

//*
//  outline 1px dashed red !important
+below(1380px)
  .lock-img
    right 0


+below(mobile.desktop)
  .container
    padding 0 20px


+below(1080px)
  .header
    padding-left 45px
+below(1040px)
  .fixed
    .container
      padding 0 20px
+below(mobile.pad)
  .book
    width: 40%
  .container
    padding 0 20px 0 45px
  .reviews__img
    width: 100px
    height: 100px
  .line-to-point
    height: 610px
  .line-1,
  .line-2,
  .line-3,
  .line-4,
  .line-5,
  .line-6
    display none
  .header
    padding-left 45px
  .service
    padding 107px 0px 0 0px
  #container_paralax
    .container
      padding 0
+below(1060px)
    .note
      max-width 40%
+below(1024px)
  .line-to-point
    height 376px
    transform rotate(158.5deg)
+below(968px)
  .book
    width: 30%
  .swiper-container
    position: relative
    top -60px
    left 0px
    width 60%
  .note
    max-width 30%
  .section-reviews
    min-height auto

+below(768px)
  .mob_button
    display: block
  .reviews
    &__text
      margin 0 auto
  .mouse
    position: relative
    bottom 20px
  .nav
    width 100%
    padding 100px 0
    background #3f404b
    position fixed
    right 0
    font-size 14px
    z-index: 1
    top 0

    &__item
      display: block
      text-align center
      text-align center
      letter-spacing 1.1px
      padding-bottom 85px
      text-transform uppercase
      &:hover
        &:before
          display none
      &:last-child
        padding-bottom 0



  .book
    position static
    margin 45px auto 0 auto
    display: block
    height auto
    width auto
    max-width: 100%

  .book
    img
      max-width 100%
      display: block
      marign 0 auto

  .title_h1
    padding 0 20px
  .fixed .container
    padding 0 40px
  .note
    max-width: 100%
  .contacts
    position relative
    z-index 1
    j_center(768px)
    margin 0 40px
  .container
    padding 0 40px
  .resize-note
    position static
  .resize-note
    max-width 100%
    display: block
  .header-description
    width: auto
  .service-block
    padding 0px
  .header
    padding 100px 20px 20px 20px
    display block
    .btn
      margin 0 auto
   .btn
     width: 264px
  .btn--invert .btn__order-price
    right 0
  .not--float .service-block__left
    float none
    margin 0 auto
  .swiper-container
    float none
    width: auto;
    padding 75px 0px 30px 0px
    margin 145px auto 0 auto
  .service-block__left,.service-block__right

    margin 0 auto
    width auto
    float none
  .service-list
    padding-left 0
  .btn__order-price
    right 0
    left auto
  .btn__show-price
    left 0
    right auto
    &:hover
      width 55px
  .service-block .white-block
    padding 20px
  .services
    padding 0 20px
  .reviews__item
    padding 115px 20px 66px 20px
  .reviews__img
    left 0
    right 0
    margin auto
  .title_person,.person_position
    display: block
    text-align center
  .mac-img,.lock-img,._1c-img
    display: none
  .swiper-nav
     position: absolute
     bottom 0
     width 90px
     left: 0
     right: 0
     margin auto
     height 45px
     .swiper-button-prev
       left 0
       top -20px
       right auto
     .swiper-button-next
       right 0
       top 0
       left auto

  .b-clients
    width: auto
    padding 0 0px 0px 0px
  .pos-abs
    position: static;
    width: auto

  .contacts
    padding 20px
  .contacts .btn
    display: none
  .col-7,.col-3
    float none
    width:auto
    padding 0 20px
    margin 0
  footer.container p
      width 90%
  footer
    padding 182px 20px 0 20px!important
  footer p
    width: auto
  .b-socoals
    position: absolute
    top 71px
    left: 0
    text-align center
    right: 0
    margn auto
    .coc-item
      width: 45px
      height: 45px
      i
        line-height 45px
   footer
     position: relative
  .develop
    margin-top 45px
    margin-bottom 45px
    text-align center
    .red
      padding-left 10px
    &:last-child
      float: none
      margin 0 auto 35px auto
      display: block
  .header-description
    padding 0 20px
  .map
    height: 540px
  .image-service
    display: block
    margin 0 auto
  .title--right
    float none
  .line-to-point
    height: 396px
    transform: rotate(180deg)
    left: 0
    bottom: 0
    right: 0
    margin: auto
+below(540px)
  .logo-full
    display none
  .logo-mini
    display inline-block
  .fixed
    .container
      padding 0 10px
  .title_h1,.header-description
    padding 0
  .btn
    width: 245px
  .header
    padding: 100px 10px 20px 10px;
  .container
    padding 0 10px
  .services__item
    margin-right 0
    width auto
  .services
    padding 0
  .service-block .icon
    width 30px
    right 30px
    top 75px
  .service-block:last-child
    padding-bottom: 57px
  .service-block:first-child
    padding-top 30px
  .title_person
    border-right none
  .b-clients__item img
    max-width 110px
  .title_h1
    font-size 24px
    line-height 40px
    margin-bottom 40px
  .title--big
    font-size 24px
    line-height 38px
  .title-red
    font-size 24px
  .contacts
    margin 0 10px
  .title--right
    float none
    font-size 28px
    line-height 60px
  .modal
    width: auto
    height 300px
    padding 20px
    .group-input
      float none
      display: block
      margin-right 0
      width auto
      input
        width 250px
  footer
    padding 182px 10px 0 10px!important
  footer.container .copy
    padding 0
  .col-7, .col-3
    padding 0
  .phone
    display inline-block
    float none
  .logo
    margin-right 24px


